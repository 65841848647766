import { useEffect, FC } from 'react';
import './style.scss';
import { AuthProps, AuthType } from './types';
import { LoginSSO } from './LoginSSO';
import { Login } from './Login';
import { ResetPassword } from './ResetPassword';
import { OnlyEmail } from './OnlyEmail';
import { ReactComponent as Logo } from '../../assets/ora.svg';
import { LOGIN_TYPE } from '../../env';
import logo from './intuitive-logo.png';

const getContent = (type: AuthType) => {
  switch (type) {
    case AuthType.LOGIN:
      return LOGIN_TYPE === 'SSO' ? <LoginSSO /> : <Login />;
    case AuthType.RESET_PASSWORD:
      return <ResetPassword />;
    case AuthType.ONLY_EMAIL:
      return <OnlyEmail />;
    default:
      return <Login />;
  }
};

export const Auth: FC<AuthProps> = ({ type }) => {
  useEffect(() => {
    const clinicSlug = localStorage.getItem('hospital-refId');
    const resetToken = localStorage.getItem('resetToken');

    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('resetToken');

    if (clinicSlug) {
      localStorage.setItem('hospital-refId', `${clinicSlug}`);
    }

    if (resetToken !== 'null') {
      localStorage.setItem('resetToken', `${resetToken}`);
    }
  }, []);
  return (
    <div className="h-screen p-[4rem] bg-grey-bg">
      <div className="max-w-[136rem] flex h-full m-auto">
        <div className="left flex flex-col justify-center items-center relative h-full w-1/2">
          <div className="flex flex-col justify-between w-[42rem] h-[40rem] z-[3]">
            <div className="logo">
              <Logo />
            </div>
            <div className="slogan">
              <div className="text-4150 text-cultured font-semibold uppercase tracking-[0.1rem]">
                Operating Room Analytics
              </div>
            </div>
            <div className=""></div>
          </div>
          <div className="absolute z-[4] bottom-32">
            <img className="w-120" src={logo} alt="" />
            <div className="text-center w-full text-cultured tracking-widest font-lato">
              Beta Version (1.1.5)
            </div>
          </div>
          <div className="absolute w-full h-full bg-persianBlue z-[2] opacity-70 rounded-[2.8rem]"></div>
          <div className="absolute w-full h-full bg-doctor bg-cover bg-no-repeat -scale-x-100 z-[1] rounded-[2.8rem] "></div>
        </div>
        <div className="right flex flex-col justify-between items-center w-1/2 h-full">
          {getContent(type)}
        </div>
      </div>
    </div>
  );
};
